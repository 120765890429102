import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { content } from "../components/Constant";
import { ServicesSection } from "../components/ServicesSection";
import Hero from "../components/Hero";
import { DeliverySection } from "components/DeliverySection";
import IndustriesSection from "../components/IndustriesSection";
import CTA from "components/CTA";

function Services() {
  return (
    <div>
      <div className="relative z-50">
          <Navbar />
        </div>
      <Hero/>
      <ServicesSection />
      <CTA />
      <IndustriesSection {...content.industriesSection} />
      <DeliverySection />
      <Footer isFromHomepage={false} />
    </div>
  );
}

export default Services;
