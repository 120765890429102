import React from 'react';
import { useLocation } from 'react-router-dom';
import ServiceCard from './ServiceCard';

export const ServicesSection = () => {
  const location = useLocation();

  // Destructure title and services from the state, provide defaults
  const { title = 'Our Services', services = [] } = location.state || {};

  return (
    <section className="py-16 bg-gray-50">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold mb-12  text-gray-800">What you get with our {title} services</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {services.map((service, index) => (
            <ServiceCard key={index} {...service} />
          ))}
        </div>
      </div>
    </section>
  );
};
