import Lottie from "lottie-react";

const Statistics = () => {
  return (
    <div className="w-full bg-white py-16 px-10">
      <div className="max-w-[1240px] mx-auto grid md:grid-cols-2 md:flex-row-reverse">
        <Lottie
          className="w-[400px] mx-auto my-4"
          animationData={require("../assets/chat.json")}
          loop={true}
        />
        <div className="flex flex-col justify-center">
          <p className="text-[#00df9a] font-bold text-lg uppercase tracking-wide mb-2">
            A dive on numbers
          </p>
          <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold mb-4 text-gray-900">
            Industries and AI: a massive impact on sight
          </h1>
          <p className="text-lg text-gray-700 mb-6 leading-relaxed">
            The impact of AI is profound and moving fast. In 2018, global
            companies reported having 1.8 applications that used AI. In 2022,
            that's more than doubled to 3.8. Here are a few AI projections that
            are breathtaking in their boldness:
          </p>
          <ul className="space-y-4">
            <li className="flex items-start">
              <span className="text-[#00df9a] text-3xl leading-none mr-2">•</span>
              <p className="text-lg text-gray-700 leading-tight">
                AI is projected to <span className="font-semibold text-[#00df9a]">boost profitability</span> in all
                industries by 38% on average by 2035.
              </p>
            </li>
            <li className="flex items-start">
              <span className="text-[#00df9a] text-3xl leading-none mr-2">•</span>
              <p className="text-lg text-gray-700 leading-tight">
                <span className="font-semibold text-[#00df9a]">90% of the processes and products</span> of advanced enterprise apps will be supported by AI in the next 18 months.
              </p>
            </li>
            <li className="flex items-start">
              <span className="text-[#00df9a] text-3xl leading-none mr-2">•</span>
              <p className="text-lg text-gray-700 leading-tight">
                PwC estimates that AI may <span className="font-semibold text-[#00df9a]">contribute 15.7 trillion</span> to
                the global economy by 2030. (Yes, that's a T!) That's more than the
                combined output of China and India!
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Statistics;