import React from "react";
import { useNavigate } from "react-router-dom";
import Typed from "react-typed";
import { useLocation } from "react-router-dom";

const Hero = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Destructure title and services from the state, provide defaults
  const { title = 'AI', services = [] } = location.state || {};
  const backgroundStyle = {
    backgroundImage: 'url("/novative.jpeg")',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  };

  return (
    <div className="text-white relative">
      <div 
        style={backgroundStyle}
        className="absolute inset-0 opacity-50"
      ></div>
      <div className="max-w-[800px] mt-[-96px] w-full h-[80vh] mx-auto  flex flex-col justify-center relative z-10 pl-10"> {/* Added left padding */}
        <p className="text-[#00df9a] font-bold p-2 text-left"> {/* Aligned left */}
          Embracing a new era of technology
        </p>
        <h1 className="md:text-7xl sm:text-6xl text-4xl font-bold md:py-6 text-left"> {/* Aligned left */}
          {title} at the service
        </h1>
        <div className="flex items-center text-left"> {/* Aligned left */}
          <p className="md:text-7xl sm:text-4xl text-xl font-bold py-4">
            of your
          </p>
          <Typed
            className="md:text-7xl text-[#00df9a] sm:text-4xl text-xl font-bold md:pl-4 pl-2"
            strings={["Business", "Customers", "Performance"]}
            typeSpeed={120}
            backSpeed={140}
            loop
          />
        </div>
        <p className="md:text-2xl text-xl font-bold text-white-500 opacity-70 text-left"> {/* Aligned left */}
          Take your business to a whole new level with AI
        </p>
        <button
          className="bg-[#00df9a] w-[200px] rounded-md font-medium my-6 py-3 text-black" 
          onClick={() => {
            navigate("/contact");
          }}
        >
          Get Started
        </button>
      </div>
    </div>
  );
};

export default Hero;