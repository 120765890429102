import React from "react";

export default function Content() {
  return (
    <section className="mx-auto flex w-full flex-col px-4 py-20 bg-white lg:px-40 lg:py-40 items-center justify-center">
      <div className="w-full text-center lg:text-left">
        <div className="mb-5 flex h-16 w-16 items-center justify-center rounded-full bg-[#00df9a] text-white shadow-lg shadow-indigo-600/20 mx-auto lg:mx-0">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            className="h-6 w-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M2.25 7.125C2.25 6.504 2.754 6 3.375 6h6c.621 0 1.125.504 1.125 1.125v3.75c0 .621-.504 1.125-1.125 1.125h-6a1.125 1.125 0 01-1.125-1.125v-3.75zM14.25 8.625c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v8.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-8.25zM3.75 16.125c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v2.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-2.25z"
            />
          </svg>
        </div>
        <h2 className="mb-10 text-4xl font-bold leading-snug text-center lg:text-5xl lg:leading-snug lg:text-left">
          It's <span className="text-[#00df9a] font-extrabold">now or never,</span> make the leap!
        </h2>
        <div className="grid gap-y-12 gap-x-8 lg:grid-cols-1">
          <div>
            <p className="mb-6 border-l-4 border-[#00df9a] pl-4 text-2xl font-medium leading-10"> {/* Added font-medium */}
              Take the Lead in a fast progressing market
            </p>
            <p className="text-lg text-gray-800">
  The adoption of AI chatbots for customer support and internal information access has become <span className="font-bold">imperative</span> in today's business landscape.
</p>
<p className="text-lg text-gray-800 my-10">
From providing <span className="font-bold">24/7 availability</span> and <span className="italic">personalized</span> interactions to empowering employees with <span className="italic">instant access</span> to information and workflow optimization, AI chatbots offer a multitude of benefits. They <span className="font-bold">enhance customer satisfaction, increase productivity, and contribute to cost savings.</span> By leveraging the power of AI, organizations can create a seamless and efficient support ecosystem, delivering <span className="italic">exceptional</span> customer experiences and empowering employees to excel in their roles. Embracing AI chatbots is no longer a <span className="italic">luxury</span> but a <span className="font-bold">necessity</span> for businesses striving to stay competitive and meet the evolving expectations of customers and employees alike.
</p>
          </div>
        </div>
        <div className="mt-10 text-center lg:text-left"> {/* Added margin-top for spacing */}
          <a href="/contact" className="inline-flex items-center px-6 py-3 text-lg font-medium text-white bg-[#00df9a] rounded-lg hover:bg-[#00b37c] focus:outline-none focus:ring-2 focus:ring-[#00df9a] focus:ring-offset-2">
            Book an expert
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-5 h-5 ml-2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.25 4.5l7.5 7.5-7.5 7.5"
              />
            </svg>
          </a>
        </div>
      </div>
    </section>
  );
}