import React from "react";
import Laptop from "../assets/laptop.jpg";

const Analytics = () => {
  return (
    <div className="w-full bg-white py-16 px-4">
      <div className="max-w-[1240px] mx-auto grid md:grid-cols-2">
        <div className="flex flex-col justify-center">
          <p className="text-[#00df9a] font-bold ">Who are we?</p>
          <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-2">
            A bridge to the power of AI
          </h1>
          <p className="py-4">
            Our team of experienced AI specialists is dedicated to delivering
            exceptional results. We work closely with our clients to understand
            their unique challenges and goals, and then tailor our solutions to
            meet their specific requirements.
          </p>
          <p>
            From developing intelligent automation systems to implementing
            predictive analytics models, we help businesses unlock the full
            potential of AI for improved efficiency, productivity, and
            decision-making.
          </p>
        </div>
        <img className="w-[500px] mx-auto my-4" src={Laptop} alt="/" />
      </div>
    </div>
  );
};

export default Analytics;
