import React, { useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import { ChevronDown, ChevronRight } from "lucide-react";
import { content, details } from "components/Constant";
import ServiceLink from "./ServiceLink";

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const [servicesDropdown, setServicesDropdown] = useState(false);
  const [aiDataExpanded, setAiDataExpanded] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  const toggleServicesDropdown = () => {
    setServicesDropdown(!servicesDropdown);
  };

  const toggleAiData = (e) => {
    e.stopPropagation();
    setAiDataExpanded(!aiDataExpanded);
  };

  const serviceItems = [
    {
      title: "AI & Data",
      subItems: [
        "AI Chatbot",
        "Computer Vision",
        "Generative AI",
        "Predictive AI",
        "Data Analytics",
        "Mobile App Development",
        "Web App Development"
      ]
    },
    { title: "Mobile App Development", subIndex:5, service: "Mobile App Development" },
    { title: "Web App Development", subIndex:6, service: "Web App Development" }
  ];

  const ServiceItem = ({ item, mobile = false }) => {
    if (item.subItems) {
      return (
        <div>
          <div
            className="flex items-center justify-between cursor-pointer py-2 px-4 hover:bg-gray-700"
            onClick={toggleAiData}
          >
            <span>{item.title}</span>
            {aiDataExpanded ? <ChevronDown size={16} /> : <ChevronRight size={16} />}
          </div>
          {aiDataExpanded && (
            <div className={`bg-gray-800 ${mobile ? 'ml-4' : ''}`}>
              {item.subItems.map((subItem, subIndex) => (
                  <ServiceLink
                  subIndex={subIndex}
                  content={content}
                  details={details}
                  setServicesDropdown={setServicesDropdown}
                  setNav={setNav}
                  subItem={subItem}
                />

              ))}
            </div>
          )}
        </div>
      );
    } else {
      // Use the 'service' key to pass the correct service name to ServiceLink
      return (
        <ServiceLink
          subIndex={item.subIndex}
          content={content}
          details={details}
          setServicesDropdown={setServicesDropdown}
          setNav={setNav}
          subItem={item.service} // Pass the service name here
        />
      );
    }
  };

  return (
    <div className="flex justify-between items-center h-24 max-w-[1240px] mx-auto px-4 text-white">
      <img className="h-32 text-[#fff]" src="/Logo-white.png"/>
      <ul className="hidden md:flex">
        <li className="p-4">
          <NavLink
            to="/"
            className={(nav) => (nav.isActive ? "nav-active" : "nav-link")}
          >
            Home
          </NavLink>
        </li>
        <li className="p-4 relative">
          <div
            className="flex items-center cursor-pointer"
            onClick={toggleServicesDropdown}
          >
            <span>Services</span>
            <ChevronDown className="ml-1" size={16} />
          </div>
          {servicesDropdown && (
            <div className="absolute top-full left-0 bg-[#000300] border border-gray-700 rounded-md shadow-lg z-10 w-64">
              {serviceItems.map((item, index) => (
                <ServiceItem key={index} item={item} />
              ))}
            </div>
          )}
        </li>
        <li className="p-4">
          <NavLink
            to="/contact"
            className={(nav) => (nav.isActive ? "nav-active" : "nav-link")}
          >
            Contact
          </NavLink>
        </li>
      </ul>
      <div onClick={handleNav} className="block md:hidden">
        {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
      </div>
      <ul
        className={
          nav
            ? "fixed left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-[#000300] ease-in-out duration-500 overflow-y-auto"
            : "ease-in-out duration-500 fixed left-[-100%]"
        }
      >
        <h1 className="w-full text-3xl font-bold text-[#00df9a] m-4">Novative AI</h1>
        <li className="p-4 border-b border-gray-600">
          <NavLink
            to="/"
            className={(nav) => (nav.isActive ? "nav-active" : "nav-link")}
          >
            Home
          </NavLink>
        </li>
        <li className="p-4 border-b border-gray-600">
          <div
            className="flex items-center justify-between cursor-pointer"
            onClick={toggleServicesDropdown}
          >
            <span>Services</span>
            <ChevronDown size={16} />
          </div>
          {servicesDropdown && (
            <div className="mt-2">
              {serviceItems.map((item, index) => (
                <ServiceItem key={index} item={item} mobile={true} />
              ))}
            </div>
          )}
        </li>
        <li className="p-4">
          <NavLink
            to="/contact"
            className={(nav) => (nav.isActive ? "nav-active" : "nav-link")}
          >
            Contact
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default Navbar;