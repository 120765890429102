import React from 'react';
import { useNavigate } from 'react-router-dom';

const ServiceLink = ({ subIndex, content, details, setServicesDropdown, setNav, subItem }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    const selectedServices = details[subIndex]?.youGet || [];
    const selectedTitle = details[subIndex]?.service || 'Services';
    const selectedDeliveryTitle = details[subIndex]?.delivery.title || 'Services';
    const selectedDeliverySteps = details[subIndex]?.delivery.steps || [];

    navigate('/services', {
      state: {
        services: selectedServices,
        title: selectedTitle,
        header: selectedDeliveryTitle,
        steps: selectedDeliverySteps
      },
    });
    setServicesDropdown(false);
    setNav(false);
  };

  return (
    <button
      onClick={handleClick}
      className="block px-4 py-2 hover:bg-gray-700"
    >
      {subItem}
    </button>
  );
};

export default ServiceLink;