import { useNavigate } from 'react-router-dom';

const CTA = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/contact');
  };

  return (
    <div className="bg-gray-900 text-white flex items-center justify-between px-20 py-12 w-full">
      <div className="flex items-center">
        <div className="bg-white text-black rounded-full p-2 mr-4">
          {/* Replace with your SVG or icon */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5 13l4 4L19 7"
            />
          </svg>
        </div>
        <span className="text-lg font-semibold">
          Ready to unlock the potential of your business with AI?
        </span>
      </div>
      <button
        onClick={handleClick}
        className="bg-white text-black px-4 py-2 rounded-full font-semibold hover:bg-gray-300"
      >
        Contact Novative &rarr;
      </button>
    </div>
  );
};

export default CTA;
