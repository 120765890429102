import React, { useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { Paperclip, ChevronRight } from 'lucide-react';
import { app, db, storage } from '../firebase';
import { ref, uploadBytes } from 'firebase/storage';
import { collection, addDoc } from 'firebase/firestore';

const functions = getFunctions(app);

// Input Field Component
const InputField = ({ type, name, value, onChange, placeholder, required }) => (
  <div className="mb-4">
    <input
      type={type}
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      required={required}
      className="w-full bg-transparent border-b border-gray-600 py-2 focus:outline-none focus:border-white"
    />
  </div>
);

// Textarea Component
const TextArea = ({ name, value, onChange, placeholder, required }) => (
  <div className="mb-4">
    <textarea
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      required={required}
      className="w-full bg-transparent border-b border-gray-600 py-2 focus:outline-none focus:border-white"
    ></textarea>
  </div>
);

// File Attachment Component
const FileAttachment = ({ attachment, onChange }) => (
  <div className="mb-4 flex items-center gap-2">
    <label htmlFor="attachment" className="cursor-pointer bg-white bg-opacity-10 rounded-full p-2">
      <Paperclip className="text-white" size={20} />
    </label>
    <input
      type="file"
      id="attachment"
      onChange={onChange}
      className="hidden"
    />
    <span className="text-sm">{attachment ? attachment.name : 'Add an attachment'}</span>
  </div>
);

// Checkbox Component
const Checkbox = ({ name, checked, onChange, label }) => (
  <div className="mb-4 flex items-center gap-2">
    <input
      type="checkbox"
      name={name}
      checked={checked}
      onChange={onChange}
      className="form-checkbox"
    />
    <label className="text-sm">{label}</label>
  </div>
);

// Submit Button Component
const SubmitButton = ({ isSubmitting }) => (
  <button
    type="submit"
    disabled={isSubmitting}
    className="bg-white text-black px-6 py-2 rounded-full flex items-center justify-center"
  >
    {isSubmitting ? 'Submitting...' : 'Contact us'}
    {!isSubmitting && <ChevronRight className="ml-2" size={20} />}
  </button>
);

// Testimonial Component
const Testimonial = () => (
  <div className="flex-1 bg-gray-800 p-6 rounded-lg">
    <div className="text-4xl mb-4">"</div>
    <p className="mb-4">
      The breadth of knowledge and understanding that NOVATIVE has within its walls allows us to
      leverage that expertise to make superior deliverables for our customers. When you
      work with NOVATIVE, you are working with the top 1% of the aptitude and engineering excellence
      of the whole country.
    </p>
    <div className="flex items-center">
    <img src="/ceo.png" alt="Sam Fleming" className="rounded-full mr-4 w-[50px] h-[50px] object-cover" />
      <div>
        <p className="font-bold">Jean-Marc Smith</p>
        <p className="text-sm">President, JMK Armada</p>
      </div>
    </div>
  </div>
);

// Main Contact Form Component
const ContactForm = ({ onSubmit, formData, setFormData, attachment, setAttachment, isSubmitting }) => {
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleAttachment = (e) => {
    setAttachment(e.target.files[0]);
  };

  return (
    <form onSubmit={onSubmit} className="flex-1">
      <InputField type="text" name="fullName" value={formData.fullName} onChange={handleChange} placeholder="Full name *" required />
      <InputField type="email" name="email" value={formData.email} onChange={handleChange} placeholder="Email *" required />
      <InputField type="tel" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} placeholder="Phone number *" required />
      <InputField type="text" name="country" value={formData.country} onChange={handleChange} placeholder="Country *" required />
      <InputField type="text" name="company" value={formData.company} onChange={handleChange} placeholder="Company *" required />

      {/* Field Dropdown */}
      <div className="mb-4">
        <select
          name="field"
          value={formData.field}
          onChange={handleChange}
          className="w-full bg-transparent border-b border-gray-600 py-2 focus:outline-none focus:border-white"
        >
          <option value="">Select Field *</option>
          <option value="Accounting">Accounting</option>
          <option value="Advertising">Advertising</option>
          <option value="Aerospace">Aerospace</option>
          <option value="Agriculture">Agriculture</option>
          <option value="Architecture">Architecture</option>
          <option value="Automotive">Automotive</option>
          <option value="Banking">Banking</option>
          <option value="Biotechnology">Biotechnology</option>
          <option value="Chemical">Chemical</option>
          <option value="Construction">Construction</option>
          <option value="Consulting">Consulting</option>
          <option value="Consumer Goods">Consumer Goods</option>
          <option value="Education">Education</option>
          <option value="Electronics">Electronics</option>
          <option value="Energy">Energy</option>
          <option value="Engineering">Engineering</option>
          <option value="Entertainment">Entertainment</option>
          <option value="Environmental">Environmental</option>
          <option value="Fashion">Fashion</option>
          <option value="Finance">Finance</option>
          <option value="Food & Beverage">Food & Beverage</option>
          <option value="Government">Government</option>
          <option value="Healthcare">Healthcare</option>
          <option value="Hospitality">Hospitality</option>
          <option value="Information Technology">Information Technology</option>
          <option value="Insurance">Insurance</option>
          <option value="Legal">Legal</option>
          <option value="Manufacturing">Manufacturing</option>
          <option value="Marketing">Marketing</option>
          <option value="Media">Media</option>
          <option value="Mining">Mining</option>
          <option value="Non-profit">Non-profit</option>
          <option value="Pharmaceuticals">Pharmaceuticals</option>
          <option value="Real Estate">Real Estate</option>
          <option value="Retail">Retail</option>
          <option value="Telecommunications">Telecommunications</option>
          <option value="Transportation">Transportation</option>
          <option value="Travel">Travel</option>
          <option value="Utilities">Utilities</option>
          {/* Add more options as needed */}
        </select>
      </div>

      <TextArea name="message" value={formData.message} onChange={handleChange} placeholder="Message *" required />
      <FileAttachment attachment={attachment} onChange={handleAttachment} />
      <Checkbox name="receiveUpdates" checked={formData.receiveUpdates} onChange={handleChange} label="I want to receive news and updates once in a while" />
      <p className="text-xs mb-4">
        We will add your info to our CRM for contacting you regarding your request.
      </p>
      <SubmitButton isSubmitting={isSubmitting} />
    </form>
  );
};

// Main Contact Us Component
const ContactUs = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phoneNumber: '',
    country: 'United States',
    company: '',
    field: '', // Initialize field as empty string
    message: '',
    receiveUpdates: false
  });
  const [attachment, setAttachment] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      // 1. Upload file to storage (if any)
      let attachmentUrl = null;
      if (attachment) {
        const randomString = Math.random().toString(36).substring(2, 15);
        const storageRef = ref(storage, `attachments/${formData.fullName}-${randomString}-${attachment.name}`);
        await uploadBytes(storageRef, attachment);
        attachmentUrl = await storageRef.getDownloadURL();
      }

      // 2. Save data to Firestore
      const dataToSave = { ...formData, attachmentUrl };
      const docRef = await addDoc(collection(db, 'contact_forms'), dataToSave);
      console.log('Document written with ID: ', docRef.id);

      // 3. Optionally call the callable function (if still needed)
      // const submitForm = httpsCallable(functions, 'submitContactForm');
      // await submitForm({ ...formData, attachmentUrl });

      alert('Form submitted successfully!');
      // Reset form
      setFormData({
        fullName: '',
        email: '',
        phoneNumber: '',
        country: 'United States',
        company: '',
        field: '', // Reset field to empty string
        message: '',
        receiveUpdates: false
      });
      setAttachment(null);
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('An error occurred. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="bg-gray-900 text-white px-20 py-16 font-inter">
      <h1 className="text-4xl font-bold mb-8">Contact Us</h1>
      <div className="flex flex-col md:flex-row gap-8">
        <ContactForm
          onSubmit={handleSubmit}
          formData={formData}
          setFormData={setFormData}
          attachment={attachment}
          setAttachment={setAttachment}
          isSubmitting={isSubmitting}
        />
        <Testimonial />
      </div>
    </div>
  );
};

export default ContactUs;