export const COLOR = "#00df9a";
// Example usage:
export const content = {
  navItems: ["About us", "Services", "Industries", "Contact Us"],
  heroSection: {
    title: "Generative AI Software Development",
    description:
      "Harness the creative power of generative AI solutions to boost efficiency, streamline costs, explore innovations and reimagine customer experiences",
    buttonText: "Contact Novative",
  },
  servicesSection: [
    //AI Chatbot
    {
      title: "What you get with Novative's AI Chatbot development services",
      services: [
        {
          title: "Optimisation of complex design processes",
          description:
            "Custom generative AI solutions can streamline even the most intricate creative processes, allowing designers and engineers to identify optimal solutions faster and more effectively than traditional methods.",
        },
        {
          title: "Automated content generation and dynamic personalisation",
          description:
            "Generative AI systems, powered by machine learning and advanced Natural Language Processing (NLP) models, can revolutionise content creation, localisation and personalisation.",
        },
        {
          title: "Improved engagement with tailored customer experiences",
          description:
            "Businesses can use generative AI to create custom interfaces and innovative, interactive user experiences tailored to individual preferences, behaviours and needs.",
        },
      ],
    },
    //Computer Vision
    {
      title: "What you get with Novative's Computer Vision services",
      services: [
        {
          title: "Optimisation of complex design processes",
          description:
            "Custom generative AI solutions can streamline even the most intricate creative processes, allowing designers and engineers to identify optimal solutions faster and more effectively than traditional methods.",
        },
        {
          title: "Automated content generation and dynamic personalisation",
          description:
            "Generative AI systems, powered by machine learning and advanced Natural Language Processing (NLP) models, can revolutionise content creation, localisation and personalisation.",
        },
        {
          title: "Improved engagement with tailored customer experiences",
          description:
            "Businesses can use generative AI to create custom interfaces and innovative, interactive user experiences tailored to individual preferences, behaviours and needs.",
        },
      ],
    },

    //Predictive AI
    {
      title: "What you get with Novative's Predictive AI services",
      services: [
        {
          title: "Optimisation of complex design processes",
          description:
            "Custom generative AI solutions can streamline even the most intricate creative processes, allowing designers and engineers to identify optimal solutions faster and more effectively than traditional methods.",
        },
        {
          title: "Automated content generation and dynamic personalisation",
          description:
            "Generative AI systems, powered by machine learning and advanced Natural Language Processing (NLP) models, can revolutionise content creation, localisation and personalisation.",
        },
        {
          title: "Improved engagement with tailored customer experiences",
          description:
            "Businesses can use generative AI to create custom interfaces and innovative, interactive user experiences tailored to individual preferences, behaviours and needs.",
        },
      ],
    },

    //Data Analytics
    {
      title: "What you get with Novative's Predictive AI services",
      services: [
        {
          title: "Optimisation of complex design processes",
          description:
            "Custom generative AI solutions can streamline even the most intricate creative processes, allowing designers and engineers to identify optimal solutions faster and more effectively than traditional methods.",
        },
        {
          title: "Automated content generation and dynamic personalisation",
          description:
            "Generative AI systems, powered by machine learning and advanced Natural Language Processing (NLP) models, can revolutionise content creation, localisation and personalisation.",
        },
        {
          title: "Improved engagement with tailored customer experiences",
          description:
            "Businesses can use generative AI to create custom interfaces and innovative, interactive user experiences tailored to individual preferences, behaviours and needs.",
        },
      ],
    },
  ],
  industriesSection: {
    title: "Industry-specific use cases for generative AI",
    industries: [
      "Healthcare",
      "Retail",
      "Finance",
      "Logistics",
      "Manufacturing",
      "Oil, gas and mining",
    ],
  },
  deliverySection: {
    title: "How we deliver Data Analytics solutions",
    steps: [
      {
        title: "Discovery",
        description:
          "We allocate a team of generative artificial intelligence and machine learning experts to conduct discovery sessions in which they analyse your existing training data and delve deep into your organisational ecosystem.",
      },
      {
        title: "Generative AI solution design",
        description:
          "This stage of the generative AI model development process involves crafting a comprehensive project roadmap. We provide a detailed plan involving solution architecture design and a strategy for building and integrating a generative AI model within your infrastructure.",
      },
      {
        title: "Development and deployment",
        description:
          "Our expert team will develop and deploy a generative AI model tailored to the exact requirements created during the discovery and solution design phases. We'll ensure the generative AI system seamlessly integrates into your existing infrastructure, providing complete convergence with your operational framework.",
      },
    ],
  },
  footer: {
    columns: [
      {
        title: "Company",
        items: ["About us", "Services", "How we work", "Awards and partners"],
      },
      {
        title: "Services",
        items: [
          "Product and service design",
          "Data science",
          "Cyber security",
          "Technical feasibility study",
        ],
      },
      {
        title: "Industries",
        items: ["Logistics", "Fintech", "Retail", "Insurance"],
      },
      {
        title: "Contact Us",
        items: [
          "Ajman Chamber of Commerce, Sheikh Rashid bin Hameed Street, Al Nakheel 2, Ajman City",
        ],
      },
    ],
    copyright: "© 2021-2024 Novative AI, All rights reserved",
  },
};

//Services
export const details = [
  {
    service: "AI Chatbot",
    youGet: [
      {
        title: "Natural Language Understanding",
        description:
          "Our AI chatbot is equipped with advanced NLP capabilities, enabling it to comprehend and interpret user inputs accurately. This ensures a conversational experience that feels intuitive and human-like.",
      },
      {
        title: "24/7 Customer Support",
        description:
          "With our chatbot solution, you provide round-the-clock customer support, reducing wait times and increasing customer satisfaction. The chatbot handles common inquiries efficiently, allowing your team to focus on more complex tasks.",
      },
      {
        title: "Scalable Integration",
        description:
          "Our chatbot is designed to seamlessly integrate with your existing systems, scaling as your business grows. This ensures a consistent user experience across all customer interaction points.",
      },
    ],
    delivery: {
      title: "How we deliver AI Chatbot solutions",
      steps: [
        {
          title: "Conversational Design",
          description:
            "We design engaging and effective conversational flows for your chatbot, ensuring a seamless user experience.",
        },
        {
          title: "NLP Training & Fine-tuning",
          description:
            "We train and fine-tune the chatbot's NLP model on relevant data to ensure accurate understanding of user inputs and appropriate responses.",
        },
        {
          title: "Integration & Deployment",
          description:
            "We integrate the chatbot with your preferred communication channels (e.g., website, messaging platforms) and deploy it for immediate use.",
        },
      ],
    },
  },
  {
    service: "Computer Vision",
    youGet: [
      {
        title: "Advanced Image Recognition",
        description:
          "Our computer vision solutions leverage state-of-the-art algorithms to recognize and categorize images with precision. This enhances automation and accuracy in tasks such as quality control and surveillance.",
      },
      {
        title: "Real-time Processing",
        description:
          "Experience real-time image and video analysis, allowing for immediate responses to visual data. This is crucial for applications that require instant decision-making, such as autonomous vehicles or security monitoring.",
      },
      {
        title: "Customizable Models",
        description:
          "We provide models tailored to your specific industry needs, ensuring that the computer vision system aligns with your unique operational requirements. This customization boosts performance and relevancy.",
      },
    ],
    delivery: {
      title: "How we deliver Computer Vision solutions",
      steps: [
        {
          title: "Data Acquisition & Annotation",
          description:
            "We help you acquire and annotate the necessary image/video data to train your custom computer vision model.",
        },
        {
          title: "Model Training & Optimization",
          description:
            "We train and optimize your model using state-of-the-art techniques, ensuring high accuracy and performance.",
        },
        {
          title: "Real-time Deployment & Integration",
          description:
            "We deploy your model for real-time processing and integrate it with your existing systems and infrastructure.",
        },
      ],
    },
  },
  {
    service: "Generative AI",
    youGet: [
      {
        title: "Content Creation",
        description:
          "Our generative AI models can create various types of content, including text, images, and code, which can be used for marketing, product development, and more.",
      },
      {
        title: "Automation",
        description:
          "Generative AI can automate tasks such as data entry and report generation, freeing up your team for more creative and strategic work.",
      },
      {
        title: "Personalization",
        description:
          "Create personalized experiences for your customers with generative AI, tailoring content and recommendations to their individual needs and preferences.",
      },
    ],
    delivery: {
      title: "How we deliver Generative AI solutions",
      steps: [
        {
          title: "Model Selection & Fine-tuning",
          description:
            "We select the most suitable generative model for your needs and fine-tune it on relevant data to ensure high-quality output.",
        },
        {
          title: "Content Generation & Refinement",
          description:
            "We generate content based on your requirements and refine it through iterative feedback and improvement cycles.",
        },
        {
          title: "Ethical Considerations & Deployment",
          description:
            "We address ethical considerations related to generative AI and deploy the solution responsibly within your infrastructure.",
        },
      ],
    },
  },
  {
    service: "Predictive AI",
    youGet: [
      {
        title: "Data-Driven Insights",
        description:
          "Our predictive AI models generate actionable insights based on historical data, helping you anticipate future trends and make informed decisions. This proactive approach enhances strategic planning.",
      },
      {
        title: "Risk Mitigation",
        description:
          "Utilize predictive AI to identify potential risks before they materialize. Our models help you foresee challenges and prepare contingencies, ensuring business continuity and resilience.",
      },
      {
        title: "Improved Forecasting",
        description:
          "Enhance your forecasting capabilities with AI-driven predictions that account for various variables and scenarios. This leads to more accurate projections and better resource allocation.",
      },
    ],
    delivery: {
      title: "How we deliver Predictive AI solutions",
      steps: [
        {
          title: "Data Analysis & Feature Engineering",
          description:
            "We analyze your historical data and engineer relevant features to train accurate predictive models.",
        },
        {
          title: "Model Selection & Training",
          description:
            "We select the most appropriate predictive model for your needs and train it on your prepared data.",
        },
        {
          title: "Performance Evaluation & Deployment",
          description:
            "We evaluate the model's performance, fine-tune it as needed, and deploy it for generating predictions.",
        },
      ],
    },
  },
  {
    service: "Data Analytics",
    youGet: [
      {
        title: "Comprehensive Data Analysis",
        description:
          "Our data analytics service offers deep dives into your data, revealing patterns and insights that drive strategic decisions. We help you understand your data's story for better outcomes.",
      },
      {
        title: "Custom Dashboards",
        description:
          "We create personalized dashboards that present data in a user-friendly format, allowing you to monitor KPIs and other metrics in real-time. This visualization aids in quick, informed decision-making.",
      },
      {
        title: "Scalable Solutions",
        description:
          "Our analytics solutions are designed to scale with your business, adapting to growing data sets and evolving analytical needs. This ensures that your data strategy remains robust as your company expands.",
      },
    ],
    delivery: {
      title: "How we deliver Data Analytics solutions",
      steps: [
        {
          title: "Data Collection & Integration",
          description:
            "We collect data from various sources and integrate it into a centralized repository for analysis.",
        },
        {
          title: "Data Cleaning & Transformation",
          description:
            "We clean and transform the data to ensure accuracy and consistency, preparing it for insightful analysis.",
        },
        {
          title: "Analysis & Visualization",
          description:
            "We analyze the data using various techniques and create compelling visualizations to communicate key findings.",
        },
      ],
    },
  },
  {
    service: "Mobile App Development",
    youGet: [
      {
        title: "Cross-Platform Compatibility",
        description:
          "Develop applications for various platforms - iOS, Android - from a single codebase, ensuring broader reach and reduced development time.",
      },
      {
        title: "User-Centric Design",
        description:
          "Focus on intuitive and engaging user experiences that drive adoption and satisfaction. Our designs are tailored to mobile interactions and best practices.",
      },
      {
        title: "Performance Optimization",
        description:
          "We build applications optimized for speed and efficiency, minimizing resource consumption and ensuring a smooth user experience even on low-powered devices.",
      },
    ],
    delivery: {
      title: "How we deliver Mobile App Development solutions",
      steps: [
        {
          title: "Requirement Gathering & Analysis",
          description:
            "We thoroughly analyze your requirements to understand your target audience, functionalities, and objectives.",
        },
        {
          title: "UI/UX Design & Development",
          description:
            "We create visually appealing and user-friendly interfaces that enhance the overall app experience.",
        },
        {
          title: "Testing & Deployment",
          description:
            "Rigorous testing ensures quality and stability before deployment on respective app stores.",
        },
      ],
    },
  },
  {
    service: "Web App Development",
    youGet: [
      {
        title: "Responsive Design",
        description:
          "Develop web applications that adapt seamlessly to different screen sizes and devices, providing a consistent experience across desktops, tablets, and smartphones.",
      },
      {
        title: "Scalability & Performance",
        description:
          "Build applications capable of handling increasing traffic and data loads, ensuring optimal performance and reliability as your user base grows.",
      },
      {
        title: "Security & Maintenance",
        description:
          "Prioritize security measures to protect user data and application integrity. We provide ongoing maintenance and support to ensure the long-term stability of your web application.",
      },
    ],
    delivery: {
      title: "How we deliver Web App Development solutions",
      steps: [
        {
          title: "Planning & Architecture",
          description:
            "We plan the architecture and technology stack best suited for your web application's requirements.",
        },
        {
          title: "Front-End & Back-End Development",
          description:
            "Develop both the user interface and the server-side logic to create a fully functional web application.",
        },
        {
          title: "Testing & Deployment",
          description:
            "Thorough testing ensures quality and stability before deployment to your chosen hosting environment.",
        },
      ],
    },
  },
];
