import React from 'react';

const TeamMember = ({ name, role, quote, avatarUrl }) => (
  <div className="bg-white rounded-lg shadow-lg overflow-hidden transition-all duration-300 transform hover:scale-105">
    <div className="p-6">
      <p className="text-gray-600 italic mb-4">"{quote}"</p>
      <div className="flex items-center mt-4">
        <div className="w-12 h-12 rounded-full overflow-hidden mr-4 flex-shrink-0">
          <img 
            src={avatarUrl} 
            alt={name} 
            className="w-full h-full object-cover object-center"
          />
        </div>
        <div>
          <h3 className="font-semibold text-lg">{name}</h3>
          <p className="text-gray-500">{role}</p>
        </div>
      </div>
    </div>
  </div>
);

const WhoAreWe = () => {
  const teamMembers = [
    {
      name: "Harry Aina",
      role: "Chief Technical Executive",
      quote: "Innovation is the key to staying ahead in tech. We strive to deliver cutting-edge solutions that transform businesses.",
      avatarUrl: "/Aina.png"
    },
    {
      name: "Imo Gunnersen",
      role: "Project Manager",
      quote: "Effective collaboration and clear communication are the cornerstones of successful project delivery.",
      avatarUrl: "/Imo.png"
    },
    {
      name: "Luca Tiary",
      role: "HR Manager",
      quote: "Our people are our greatest asset. We're committed to fostering a diverse, inclusive, and growth-oriented workplace.",
      avatarUrl: "/Luca.png"
    }
  ];

  return (
    <section className="bg-gray-100 py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12">Our Lead</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {teamMembers.map((member, index) => (
            <TeamMember key={index} {...member} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhoAreWe;