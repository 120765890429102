import ScrollContext from "./../context/scrollTo.context";
import { useContext } from "react";
import {
  FaFacebookSquare,
  FaLinkedin,
} from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";

const Footer = ({ isFromHomepage }) => {
  const scrollToSection = useContext(ScrollContext);
  const navigate = useNavigate();
  return (
    <div className="max-w-[1240px] mx-auto py-16 px-4 grid lg:grid-cols-3 gap-8 text-gray-300">
      <div>
        <h1 className="w-full text-3xl font-bold text-[#fff]">Novative AI</h1>
        <p className="py-4">
          Providing AI integration worldwide, from startups to fortune 1000, to
          unlock the power of automation and boost profitability.
        </p>
        <div className="flex md:w-[75%] my-6">
          <FaFacebookSquare className="mr-5" size={30} />
          <FaLinkedin size={30} />
        </div>
      </div>
      <div className="lg:col-span-2 flex justify-between mt-6">
        <div>
          <h6 className="font-medium text-gray-400">Company</h6>
          <ul>
            <li className="py-2 text-sm">
              <Link
                to={"/"}
                onClick={() => {
                  if (isFromHomepage) scrollToSection("statistics");
                  else navigate("/");
                }}
              >
                Our impact in numbers
              </Link>
            </li>
            <li className="py-2 text-sm">
              <Link
                to={"/"}
                onClick={() => {
                  if (isFromHomepage) scrollToSection("analytics");
                  else navigate("/");
                }}
              >
                State of AI
              </Link>
            </li>
            <li className="py-2 text-sm">
              <Link
                to={"/"}
                onClick={() => {
                  if (isFromHomepage) scrollToSection("analytics");
                  else navigate("/");
                }}
              >
                About us
              </Link>
            </li>
            <li className="py-2 text-sm">
              <Link
                to={"/"}
                onClick={() => {
                  if (isFromHomepage) scrollToSection("content");
                  else navigate("/");
                }}
              >
                Make the leap
              </Link>
            </li>
          </ul>
        </div>
        <div>
          <h6 className="font-medium text-gray-400">Legal</h6>
          <ul>
            <li  className="py-2 text-sm">
              <Link
                to={"/privacy"}
                onClick={() => 
                  navigate("/privacy")
                }
              >Policy</Link>
              </li>
            <li  className="py-2 text-sm">
              <Link
                to={"/terms"}
                onClick={() => {
                  navigate("/terms");
                }}
              >Terms
              </Link>
              </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
