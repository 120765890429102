import { useRef, useEffect } from "react";
import Analytics from "../components/Analytics";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
import Content from "../components/Content";
import Statistics from "../components/Statistics";
import WorkedWithSection from "../components/WorkedWithSection";
import ScrollContext from "../context/scrollTo.context";
import ContactUs from "../components/ContactUs";
import WhoAreWe from "components/WhoAreWe";
import TechnologyUsed from "components/TechnologyUsed";

const AnimatedSection = ({ children, id }) => {
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate-slide-up');
          observer.unobserve(entry.target);
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1
      }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <div ref={sectionRef} id={id} className="transition-all duration-1000 ease-out">
      {children}
    </div>
  );
};

function Accueil() {
  const sectionRefs = {
    hero: useRef(null),
    workedWith: useRef(null),
    technology: useRef(null),
    statistics: useRef(null),
    analytics: useRef(null),
    whoarewe: useRef(null),
    content: useRef(null),
    contact: useRef(null),
  };

  const scrollToSection = (section) => {
    sectionRefs[section].current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <ScrollContext.Provider value={scrollToSection}>
      <div className="relative">
        <div className="relative z-50">
          <Navbar />
        </div>
        <div className="relative z-0">
          <AnimatedSection id="hero">
            <div ref={sectionRefs.hero}>
              <Hero />
            </div>
          </AnimatedSection>
          <AnimatedSection id="workedWith">
            <div ref={sectionRefs.workedWith}>
              <WorkedWithSection />
            </div>
          </AnimatedSection>
          <AnimatedSection id="technology">
            <div ref={sectionRefs.technology}>
              <TechnologyUsed />
            </div>
          </AnimatedSection>
          <AnimatedSection id="statistics">
            <div ref={sectionRefs.statistics}>
              <Statistics />
            </div>
          </AnimatedSection>
          <AnimatedSection id="analytics">
            <div ref={sectionRefs.analytics}>
              <Analytics />
            </div>
          </AnimatedSection>
          <AnimatedSection id="whoarewe">
            <div ref={sectionRefs.whoarewe}>
              <WhoAreWe />
            </div>
          </AnimatedSection>
          <AnimatedSection id="content">
            <div ref={sectionRefs.content}>
              <Content />
            </div>
          </AnimatedSection>
          <AnimatedSection id="contact">
            <div ref={sectionRefs.contact}>
              <ContactUs />
            </div>
          </AnimatedSection>
          <Footer isFromHomepage={true} />
        </div>
      </div>
    </ScrollContext.Provider>
  );
}

export default Accueil;