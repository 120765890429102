import React, { useState, useEffect } from 'react';
import { TrendingUp, Users, Building2, Globe, DollarSign, BarChart } from 'lucide-react';

const AnimatedStat = ({ icon: Icon, value, description }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const duration = 2000; // Animation duration in milliseconds
    const steps = 100; // Number of steps in the animation
    const increment = value / steps;
    let current = 0;

    const timer = setInterval(() => {
      current += increment;
      if (current >= value) {
        clearInterval(timer);
        setCount(value);
      } else {
        setCount(Math.floor(current));
      }
    }, duration / steps);

    return () => clearInterval(timer);
  }, [value]);

  return (
    <div className="flex flex-col items-center p-4 bg-white rounded-lg shadow-md">
      <Icon className="w-12 h-12 text-[#00df9a] mb-2" />
      <h3 className="text-3xl font-bold text-gray-800">{count}</h3>
      <p className="text-sm text-gray-600 text-center">{description}</p>
    </div>
  );
};

const WorkedWithSection = () => {
  const stats = [
    { icon: Building2, value: 109, description: "AI Projects Completed" },
    { icon: Users, value: 47, description: "Happy Clients" },
    { icon: Globe, value: 16, description: "Countries Served" },
    { icon: DollarSign, value: 250, description: "Thousand in Revenue" },
    { icon: TrendingUp, value: 95, description: "Success Rate (%)" },
    { icon: BarChart, value: 38, description: "AI Models Deployed" },
  ];

  return (
    <section className="bg-gray-100 py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center text-gray-800 mb-12">Our Impact in Numbers</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {stats.map((stat, index) => (
            <AnimatedStat key={index} {...stat} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default WorkedWithSection;