import React from 'react'

const ServiceCard = ({ title, description }) => (
    <div className="bg-white rounded-xl shadow-lg transition-all duration-300 hover:shadow-xl hover:-translate-y-1 p-6">
      <div className="flex items-center space-x-4 mb-4">
        <h3 className="text-2xl font-semibold text-gray-800">{title}</h3>
      </div>
      <p className="text-gray-600">{description}</p>
    </div>
  );
  export default ServiceCard;