import React from 'react'
import { 
  Briefcase,
  Layers, 
  ShoppingCart, 
  TrendingUp, 
  Globe, 
  CreditCard, 
  Truck,
  UserCheck
} from 'lucide-react'

// Industry Card Component
const IndustryCard = ({ name }) => {
  let Icon;
  switch (name) {
    case 'Business Services':
      Icon = Briefcase;
      break;
    case 'Manufacturing':
      Icon = Layers;
      break;
    case 'Retail':
      Icon = ShoppingCart;
      break;
    case 'Finance':
      Icon = TrendingUp;
      break;
    case 'Technology':
      Icon = Globe;
      break;
    case 'Healthcare':
      Icon = CreditCard;
      break;
    case 'Logistics':
      Icon = Truck;
      break;
    case 'Human Resources':
      Icon = UserCheck;
      break;
    default:
      Icon = Briefcase;
  }

  return (
    <div className="bg-white p-6 rounded-lg shadow-md text-center">
      <Icon className="mx-auto mb-4 w-12 h-12 text-gray-500" />
      <p className="font-semibold">{name}</p>
    </div>
  )
}

const IndustriesSection = ({ title, industries }) => (
  <section className="py-16 bg-gray-100">
    <div className="container mx-auto px-4">
      <h2 className="text-3xl font-bold mb-12">{title}</h2>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
        {industries.map((industry, index) => (
          <IndustryCard key={index} name={industry} />
        ))}
      </div>
    </div>
  </section>
)

export default IndustriesSection