import { Check } from 'lucide-react'
import { useLocation } from 'react-router-dom'

// Delivery Step Component
export const DeliveryStep = ({ number, title, description }) => (
  <div className="flex items-start">
    <div className="bg-[#00df9a] text-white rounded-full w-10 h-10 flex items-center justify-center mr-6 flex-shrink-0">
      {number}
    </div>
    <div>
      <h3 className="text-lg font-bold mb-2 text-gray-800">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </div>
  </div>
)

// Delivery Section Component
export const DeliverySection = () => {
  const location = useLocation();
  const { header, steps } = location.state || {};

  return (
  <section className="py-20 bg-gray-100">
    <div className="container mx-auto px-4">
      <div className="flex items-center mb-12">
        <Check className="w-12 h-12 text-[#00df9a] mr-4" />
        <h2 className="text-3xl font-bold text-gray-800">{header}</h2>
      </div>
      <div className="space-y-12">
        {steps.map((step, index) => (
          <DeliveryStep key={index} number={index + 1} {...step} />
        ))}
      </div>
    </div>
  </section>
)}