import React from 'react';

const TermsOfService = () => (
  <div className="bg-gray-900 text-white px-20 py-16 font-inter">
    <h2 className="text-2xl font-bold mb-4">Terms of Service</h2>
    <p className="mb-4">
      Welcome to Novative AI LTD's website, located at novative-ai.com. These terms and conditions outline the rules and regulations for the use of our website and the products and services we provide.
    </p>
    <p className="mb-4">
      By accessing this website or using our products and services, you agree to be bound by these terms and conditions. If you do not agree to these terms, please do not use our website or services.
    </p>

    <h3 className="text-xl font-bold mb-2">1. Acceptance of Terms</h3>
    <p className="mb-4">
      By using our website or accessing any of our products or services, you agree to be bound by these terms and conditions, as well as our Privacy Policy, which is incorporated herein by reference. If you do not agree to these terms, you are not authorized to use our website or services.
    </p>

    <h3 className="text-xl font-bold mb-2">2. Use of the Website</h3>
    <p className="mb-4">
      Our website is provided for your personal, non-commercial use only. You agree not to use our website or services for any unlawful or unauthorized purpose. You also agree not to interfere with the security of our website or the networks connected to it.
    </p>

    <h3 className="text-xl font-bold mb-2">3. Intellectual Property</h3>
    <p className="mb-4">
      All content and materials on our website, including but not limited to text, graphics, logos, images, and software, are the property of Novative AI LTD or its licensors and are protected by copyright, trademark, and other intellectual property laws. You may not modify, copy, distribute, transmit, display, reproduce, or create derivative works from our website or services without our prior written permission.
    </p>

    <h3 className="text-xl font-bold mb-2">4. User Submissions</h3>
    <p className="mb-4">
      Any information, data, or content you submit to our website or services must be your own original work and must not infringe on the intellectual property rights of others. By submitting content to us, you grant us a non-exclusive, royalty-free, perpetual, and worldwide license to use, reproduce, modify, and display that content in connection with our website and services.
    </p>

    <h3 className="text-xl font-bold mb-2">5. Limitation of Liability</h3>
    <p className="mb-4">
      Novative AI LTD will not be liable for any indirect, incidental, special, or consequential damages, including but not limited to lost profits or business interruption, arising out of or related to your use of our website or services. Our total liability for any claims related to our website or services shall not exceed $100.
    </p>

    <h3 className="text-xl font-bold mb-2">6. Governing Law and Jurisdiction</h3>
    <p className="mb-4">
      These terms and conditions shall be governed by and construed in accordance with the laws of the United Kingdom. Any disputes arising out of or related to these terms and conditions shall be resolved in the courts of the United Kingdom.
    </p>

    <h3 className="text-xl font-bold mb-2">7. Modifications to the Terms of Service</h3>
    <p className="mb-4">
      Novative AI LTD reserves the right to modify these terms and conditions at any time. Any changes will be effective immediately upon posting the revised terms on our website. Your continued use of our website or services after any such changes constitutes your acceptance of the new terms and conditions.
    </p>

    <h3 className="text-xl font-bold mb-2">Contact Us</h3>
    <p className="mb-4">
      If you have any questions about these Terms of Service, please contact us:
    </p>
    <ul className="list-disc ml-6 mb-4">
      <li>By email: info@novative-ai.com</li>
      <li>By visiting this page on our website: novative-ai.com/contact</li>
      <li>By mail: 26/28 Bedford Row, London, United Kingdom, WC1R 4LP</li>
    </ul>
  </div>
);

export default TermsOfService;