import React from 'react';

const PrivacyPolicy = () => (
  <div className="bg-gray-900 text-white px-20 py-16 font-inter">
    <h2 className="text-2xl font-bold mb-4">Privacy Policy</h2>
    <p className="mb-4">
      <strong>Novitive AI LTD</strong> ("us", "we", or "our") operates the <strong>novative-ai.com</strong> website (the "Service"). This Privacy Policy informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data.
    </p>
    <p className="mb-4">
      We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy.
    </p>

    <h3 className="text-xl font-bold mb-2">Information Collection and Use</h3>
    <p className="mb-4">
      We collect several different types of information for various purposes to provide and improve our Service to you.
    </p>

    <h3 className="text-xl font-bold mb-2">Types of Data Collected</h3>
    <h4 className="text-lg font-bold mb-2">Personal Data</h4>
    <p className="mb-4">
      While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to:
    </p>
    <ul className="list-disc ml-6 mb-4">
      <li>Email address</li>
      <li>First name and last name</li>
      <li>Phone number</li>
      <li>Cookies and Usage Data</li>
    </ul>

    <h4 className="text-lg font-bold mb-2">Usage Data</h4>
    <p className="mb-4">
      We may also collect information about how the Service is accessed and used ("Usage Data"). This Usage Data may include information such as your computer's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers, and other diagnostic data.
    </p>

    <h3 className="text-xl font-bold mb-2">Use of Data</h3>
    <p className="mb-4">
      Novitive AI LTD uses the collected data for various purposes:
    </p>
    <ul className="list-disc ml-6 mb-4">
      <li>To provide and maintain the Service</li>
      <li>To notify you about changes to our Service</li>
      <li>To allow you to participate in interactive features of our Service when you choose to do so</li>
      <li>To provide customer care and support</li>
      <li>To provide analysis or valuable information so that we can improve our Service</li>
      <li>To monitor the usage of our Service</li>
      <li>To detect, prevent, and address technical issues</li>
    </ul>

    <h3 className="text-xl font-bold mb-2">Transfer of Data</h3>
    <p className="mb-4">
      Your information, including Personal Data, may be transferred to and maintained on computers located outside of your state, province, country, or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction.
    </p>
    <p className="mb-4">
      If you are located outside the United Kingdom and choose to provide information to us, please note that we transfer the data, including Personal Data, to the United Kingdom and process it there.
    </p>
    <p className="mb-4">
      Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.
    </p>

    <h3 className="text-xl font-bold mb-2">Disclosure of Data</h3>
    <p className="mb-4">
      Novitive AI LTD may disclose your Personal Data in the good faith belief that such action is necessary to:
    </p>
    <ul className="list-disc ml-6 mb-4">
      <li>Comply with a legal obligation</li>
      <li>Protect and defend the rights or property of Novitive AI LTD</li>
      <li>Prevent or investigate possible wrongdoing in connection with the Service</li>
      <li>Protect the personal safety of users of the Service or the public</li>
      <li>Protect against legal liability</li>
    </ul>

    <h3 className="text-xl font-bold mb-2">Security of Data</h3>
    <p className="mb-4">
      The security of your data is important to us but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.
    </p>

    <h3 className="text-xl font-bold mb-2">Service Providers</h3>
    <p className="mb-4">
      We may employ third-party companies and individuals to facilitate our Service ("Service Providers"), provide the Service on our behalf, perform Service-related services, or assist us in analyzing how our Service is used.
    </p>
    <p className="mb-4">
      These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.
    </p>

    <h3 className="text-xl font-bold mb-2">Links to Other Sites</h3>
    <p className="mb-4">
      Our Service may contain links to other sites that are not operated by us. If you click a third-party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.
    </p>
    <p className="mb-4">
      We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.
    </p>

    <h3 className="text-xl font-bold mb-2">Changes to This Privacy Policy</h3>
    <p className="mb-4">
      We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
    </p>
    <p className="mb-4">
      We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the "effective date" at the top of this Privacy Policy.
    </p>
    <p className="mb-4">
      You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
    </p>

    <h3 className="text-xl font-bold mb-2">Contact Us</h3>
    <p className="mb-4">
      If you have any questions about this Privacy Policy, please contact us:
    </p>
    <ul className="list-disc ml-6 mb-4">
      <li>By email: info@novative-ai.com</li>
      <li>By visiting this page on our website: novative-ai.com/contact</li>
      <li>By mail: 26/28 Bedford Row, London, United Kingdom, WC1R 4LP</li>
    </ul>
  </div>
);

export default PrivacyPolicy;