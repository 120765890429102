import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ContactUs from "components/ContactUs";

function Contacts() {
  return (
    <div>
      <Navbar />
      <ContactUs />
      <Footer isFromHomepage={false} />
    </div>
  );
}

export default Contacts;
